.compose-form .button {
  text-transform: none;
}

.landing-page .header-wrapper .mascot {
  bottom: -16px;
  left: 90px;
}

.drawer__inner:first-child {
  isolation: isolate;
}

.drawer__inner > .compose-form {
  z-index: 10;
}

.privacy-dropdown__dropdown {
  z-index: 20;
}

.drawer__inner > .spacer {
  flex: 1;
}

.drawer__inner > .mascot {
  z-index: 0;
  flex: 0 1;
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: contain;
  min-height: 10em;
  a {
    color: inherit;
  }
}

.mascot .mascot-credit {
  position: absolute;
  bottom: 1em;
  right: 1em;
  margin: 0;
  text-shadow:
    -1px -1px 0 black,
     1px -1px 0 black,
    -1px  1px 0 black,
     1px  1px 0 black
  ;
  color: white;
  opacity: 0.8;
}

.drawer__inner {
  background-image: none;
}

@media screen and (min-width: 631px) {
  body.wide-columns
    .columns-area:not(.columns-area--mobile) // this bit excludes the single-column UI
    .column {
    max-width: 40em;
    flex: 1 0 30em;
  }
}

body.purple-shades {
  filter: url('#purple-shades');
}

.purple-shades-container {
  position:absolute; width:0; height:0;
}

.confetti-overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.status__wrapper, .detailed-status {
  position: relative;
  overflow: hidden;
  // this prevents the confetti overlay from escaping
}

.landing-page__call-to-action {
  overflow: visible;
}

.landing-page__mascot {
  left: 0;
  height: 126px;
}

a.status-link:not(.mention):not(.hashtag) {
  text-decoration: underline solid;
  text-decoration-color: rgba($ui-secondary-color, .4);
  &:hover, &:active, &:focus {
    text-decoration-color: inherit;
  }
}

// temporary fix for giant mascot
body .drawer__inner__mastodon > img {
  object-fit: scale-down;
}
